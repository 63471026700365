import { ApiCallOptions, MxtsApi, ResourceActivitiesDetailsResponse, getAll } from "@maxxton/cms-mxts-api";

import { ActionType } from "../../../redux/actions";
import { CategoryFilters } from "../../page/categoryFilter/categoryFilter.types";
import { CategoryOptions } from "../../page/categoryFilter/categoryFilter.enum";
import { Dispatch } from "redux";
import { MXTS } from "../../../utils/constants";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";

export const updatePreFilterCategories = (ids: number[], selectedCategories: number[], dispatchAction: Dispatch) => {
    const action = {
        type: ActionType.DynamicFilter,
        filter: dynamicFilterType.categoryFilters,
        payload: {
            categoryFilters: {
                filterType: CategoryOptions?.activities,
                allIds: ids,
                selectedIds: selectedCategories,
            },
        },
    };
    dispatchAction(action);
};

export const filterActivitiesByCategory = (activitiesDetails: ResourceActivitiesDetailsResponse[], categoryFilters?: CategoryFilters) => {
    if (categoryFilters?.filterType === CategoryOptions?.activities) {
        return activitiesDetails?.filter((activity) => categoryFilters?.selectedIds?.includes(activity?.resourceActivity?.resortActivity?.activityCategoryId));
    }
    return activitiesDetails;
};

export const fetchActivitiesCategories = async (env: ApiCallOptions, ids?: number[]) => {
    const activityCategories = await getAll((page: number) => MxtsApi.getActivitiesCategories(env, { page, size: MXTS.MAX_RESULTS, activityCategoryId: ids }));
    return activityCategories?.content;
};
