import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { Activity, DaysChunks } from "../../page/activityPlanner/activityPlanner.types";
import { ActivityStatus, MxtsApi, ReservedResource, ResourceType } from "@maxxton/cms-mxts-api";
import { CRPLayouts, getCRPContainerLayoutOptions, getDeviceType, renderNoResultsFoundContent } from "../containerWidget.util";
import { DATE_FORMAT, ResultStyleSelector } from "../../../utils/constants";
import { RenderVirtualizedList, withVirtualization } from "../../../utils/virtualization/withVirtualization";
import { WebContent, WithId } from "@maxxton/cms-api";
import { cloneDeep, isEqual } from "lodash";
import {
    filterByCustomSubjectSelection,
    getActivitiesDetailsQueryParams,
    getDistinguishedFullDayActivities,
    getGroupedActivitySectionThroughoutDay,
    getLinkedCustomSubjects,
    mainActivityFromMultipleSections,
    removeActivityParamsFromUrl,
    scrollToDay,
    setDefaultStartDate,
    sortActivities,
    updateActivityWithSubjectName,
} from "../../page/activityPlanner/activityPlanner.util";
import { getNoDataFoundContent, getNoDataFoundTemplate, setOpacityOnHide } from "../../../components/utils";
import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../../redux/actions";
import { Button } from "reactstrap";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DisplayPattern } from "../../page/activityPlanner/activityPlanner.enum";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { backgroundColorPicker } from "../../../utils/colorpicker.util";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import namespaceList from "../../../i18n/namespaceList";
import { renderActivitySearchPanel } from "../../resultsPanel/resultsPanelUtil";

interface ActivityContainerProps {
    className?: string;
    options: WidgetOptions;
    context: CMSProvidedProperties;
    markerIds?: number[];
    showLoadMoreButton?: boolean;
    crpLayouts: CRPLayouts;
    dayActivities?: Activity[];
    renderVirtualizedList?: RenderVirtualizedList;
    hideDateLabel?: boolean;
    hideDisplayPatternClassName?: boolean;
    originalListOfActivitiesFromDayView?: Activity[];
    handleTimeSelectionForDayView?: (oldResourceActivityDetailsId: number, newResourceActivityDetailsId: number) => void;
    enableWeekScrolling?: boolean;
    week?: DaysChunks[];
    updateDayViewDate?: (selectedDate: string) => void;
    selectedDayIndex?: string;
}

interface ActivityContainerStoreProps {
    dynamicFilter?: DynamicFilter;
    isMyEnvWidget?: boolean;
}

export type ActivitySearchContainerBaseProps = ActivityContainerProps & ActivityContainerStoreProps;

/* eslint-disable max-lines-per-function */
const ActivitySearchContainerBase = (props: ActivitySearchContainerBaseProps): JSX.Element => {
    const {
        context,
        options,
        isMyEnvWidget,
        crpLayouts,
        dayActivities,
        className,
        renderVirtualizedList,
        hideDateLabel,
        hideDisplayPatternClassName,
        originalListOfActivitiesFromDayView,
        handleTimeSelectionForDayView,
        enableWeekScrolling,
        updateDayViewDate,
        selectedDayIndex,
    } = props;
    const {
        resortIdMultiSelector,
        contentType,
        activityFallbackTemplateId,
        activityFallbackWebContentId,
        showOnlyPublishedActivity,
        showCancelledActivities,
        localizedOptions,
        cancelledActivitiesBackground,
    } = options;
    const { site, currentLocale } = context;

    const [activities, setActivities] = React.useState<Activity[]>(dayActivities || []);
    const [originalListOfActivities, setOriginalListOfActivities] = React.useState<Activity[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [deviceType, setDeviceType] = React.useState<"mobile" | "tablet" | undefined>(undefined);
    const [fallbackTemplate, setFallbackTemplate] = React.useState<JSX.Element[] | null>(null);
    const [fallbackWebContent, setFallbackWebContent] = React.useState<(WebContent & WithId) | null>(null);
    const [slicedActivities, setSlicedActivities] = React.useState<Activity[]>([]);
    const [fromIndex] = React.useState<number>(0);
    const [toIndex, setToIndex] = React.useState<number>(+options.defaultNumberOfTypes! || 5);
    const [isAutoScrolling, setIsAutoScrolling] = React.useState<boolean>(false);
    const isInitialRender = React.useRef<boolean>(true);

    const myEnvState = useSelector((state: State) => (isMyEnvWidget ? state.myEnvState : {}));
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter || {});
    const { selectedActivityCustomSubjects } = useSelector((state: State) => state.activityPlannerState || {});
    const dispatchAction = useDispatch();
    const { resourceActivityDetailsId, resourceActivityDetailsIds, resortActivityId, activityTicketQuantity } = dynamicFilter;
    const { displayTypeContainer, displayTypeChild, targetCrpWidgets } = getCRPContainerLayoutOptions(undefined, deviceType, crpLayouts, options, { isMyEnvWidget });
    const hideWidget: string = setOpacityOnHide(options);
    const [resultCount, setResultCount] = React.useState<string | undefined>(undefined);
    const scrollObserver = React.useRef<HTMLDivElement | null>(null);
    const defaultNumberOfMonths: number = +options.defaultNumberOfMonths! || 3;
    const cancelledActivityText = getLocalizedContent({ site, currentLocale, localizedContent: localizedOptions || [], keys: ["cancelledActivitiesText"] })?.cancelledActivitiesText;

    React.useEffect(() => {
        if (!options.showLoadMoreButton) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (enableWeekScrolling) {
            if (isInitialRender.current) {
                // Don't scrollToDay on initial render
                isInitialRender.current = false;
                return;
            }
            if (selectedDayIndex && updateDayViewDate && !isAutoScrolling) {
                setIsAutoScrolling(true);
                scrollToDay(scrollObserver, selectedDayIndex, updateDayViewDate);
                setTimeout(() => setIsAutoScrolling(false), 200);
            }
        }
    }, [selectedDayIndex, updateDayViewDate]);
    React.useEffect(() => {
        setDeviceType(getDeviceType(!!options.resultsPanelTablet, !!options.resultsPanelMobile));
    }, [options.resultsPanelMobile, options.resultsPanelTablet]);
    React.useEffect(() => {
        setDefaultStartDate(options, dynamicFilter, dispatchAction);
        removeActivityParamsFromUrl(dispatchAction);
    }, [resourceActivityDetailsId, resourceActivityDetailsIds, resortActivityId, activityTicketQuantity]);
    React.useEffect(() => {
        const fetchActivities = async () => {
            setIsLoading(true);
            const env = await getMxtsEnv(context, context?.currentLocale.code);
            let obtainedResourceActivities = dayActivities?.length ? dayActivities : activities;
            let resourceActivityDetailsIds: number[] = [];

            // Set content type's resortid in the dynamic filter
            const resortids = resortIdMultiSelector?.map((resortIdSelector) => resortIdSelector.value) || [];
            const dynamicFilterResortIds = dynamicFilter.resortids || [];
            const allResortIds = [...new Set([...resortids, ...dynamicFilterResortIds])];
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.updateresorts,
                payload: {
                    resortids: allResortIds?.length ? allResortIds : undefined,
                },
            };
            if (!isEqual(dynamicFilterResortIds, allResortIds)) {
                dispatchAction(action);
            }

            if (isMyEnvWidget && myEnvState.selectedReservationId) {
                // When widget in used in myEnv, this block will collect booked activity Ids.
                const reservedResources: ReservedResource[] = (await MxtsApi.getReservedResource(env!, { size: 1000 }, [{ key: "reservationId", value: myEnvState.selectedReservationId }]))?.content;
                resourceActivityDetailsIds = reservedResources
                    .filter((reservedResource) => reservedResource.type === ResourceType.RESOURCEACTIVITY)
                    .map((reservedResource) => Number(reservedResource.resourceActivityDetailsId));
            }
            if ((!isMyEnvWidget && !dayActivities?.length) || (isMyEnvWidget && resourceActivityDetailsIds.length)) {
                const startDate = dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS) : moment().format(DATE_FORMAT.MXTS);
                const queryParamsArgs = {
                    resortIdMultiSelector,
                    mxtsApi: context.mxtsApi,
                    env,
                    dynamicFilter,
                    startDate,
                    endDate: dynamicFilter.enddate
                        ? moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)
                        : moment(startDate, DATE_FORMAT.MXTS).add(defaultNumberOfMonths, "M").format(DATE_FORMAT.MXTS),
                    contentType,
                    resourceActivityDetailsIds,
                    showOnlyPublishedActivity,
                    showCancelledActivities,
                };
                const detailParams = await getActivitiesDetailsQueryParams(queryParamsArgs);
                obtainedResourceActivities = (await MxtsApi.getResourceActivitiesDetails(env, detailParams))?.content;
                if (showOnlyPublishedActivity && showCancelledActivities) {
                    obtainedResourceActivities = obtainedResourceActivities.filter(
                        (activity) => activity.status === ActivityStatus.CANCELLED || (activity.status === ActivityStatus.PUBLISHED && activity.published)
                    );
                }
                obtainedResourceActivities = await updateActivityWithSubjectName(obtainedResourceActivities, MxtsApi, env);
                // Just before grouping the activities, store the original activities in state variable so that we can use to find selected ResourceActivityDetailsId in handleTimeSelection function
                setOriginalListOfActivities(cloneDeep(obtainedResourceActivities));
                obtainedResourceActivities = getGroupedActivitySectionThroughoutDay(obtainedResourceActivities);
                obtainedResourceActivities = mainActivityFromMultipleSections(obtainedResourceActivities, options);
            } else {
                if (enableWeekScrolling && props.week?.length) {
                    const weekActivities = props.week?.flatMap((day) => day.activities);
                    obtainedResourceActivities = weekActivities;
                    setOriginalListOfActivities(weekActivities);
                } else if (originalListOfActivitiesFromDayView?.length) {
                    // This will store the original activities in state variable for day view so that we can use to find selected ResourceActivityDetailsId in handleTimeSelection function
                    setOriginalListOfActivities(cloneDeep(originalListOfActivitiesFromDayView));
                }
            }
            // Set the Linked Subjects in our activity results. Linked Subjects are set up in the content manager.
            obtainedResourceActivities = await getLinkedCustomSubjects(env, obtainedResourceActivities);
            if (!obtainedResourceActivities.length) {
                setActivityFallback();
            } else {
                removeActivityFallback();
            }
            setActivities([...obtainedResourceActivities]);
            setIsLoading(false);
        };
        fetchActivities();
    }, [dynamicFilter.startdate, dynamicFilter.enddate, dayActivities, dynamicFilter.resortids, selectedActivityCustomSubjects, dynamicFilter.sortingOption]);

    React.useEffect(() => {
        let clonedActivities = dayActivities?.length && !enableWeekScrolling ? dayActivities : activities;
        clonedActivities = getDistinguishedFullDayActivities(clonedActivities);
        if (dynamicFilter.sortingOption) {
            clonedActivities = sortActivities(clonedActivities, dynamicFilter.sortingOption);
        }
        // Filter out the activity based on the custom subject selection
        if (selectedActivityCustomSubjects?.length) {
            clonedActivities = filterByCustomSubjectSelection(clonedActivities, selectedActivityCustomSubjects);
        }
        setSlicedActivities(cloneDeep(clonedActivities.slice(fromIndex, toIndex)));
    }, [toIndex, fromIndex, activities, selectedActivityCustomSubjects?.length]);

    React.useEffect(() => {
        const updateToIndex = (activities: Activity[]) => {
            const { currentLocale, site } = context;
            const filterRegExp = new RegExp("\\$filteredCount");
            const totalRegExp = new RegExp("\\$totalCount");
            const { resultStyleSelector } = options;
            const totalActivities = activities?.length;
            if (!resultStyleSelector || resultStyleSelector === ResultStyleSelector.DEFAULT) {
                setResultCount(
                    getI18nLocaleString(namespaceList.genericCrud, "showingCountValue", currentLocale, site)
                        .replace(filterRegExp, options.enableVirtualization ? totalActivities.toString() : slicedActivities.length.toString())
                        .replace(totalRegExp, totalActivities.toString())
                );
            } else if (resultStyleSelector === ResultStyleSelector.SHOWING_X_RESULT) {
                setResultCount(
                    getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle1", currentLocale, site).replace(
                        filterRegExp,
                        options.enableVirtualization ? totalActivities.toString() : slicedActivities.length.toString()
                    )
                );
            } else {
                setResultCount(getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle2", currentLocale, site).replace(filterRegExp, totalActivities.toString()));
            }
        };
        updateToIndex(activities);
    }, [activities, slicedActivities]);

    const handleTimeSelection = (oldResourceActivityDetailsId: number, newResourceActivityDetailsId: number) => {
        if (dayActivities?.length && handleTimeSelectionForDayView) {
            handleTimeSelectionForDayView(oldResourceActivityDetailsId, newResourceActivityDetailsId);
        } else {
            const foundNewResourceActivityDetailsId = originalListOfActivities.find((activities) => activities.resourceActivityDetailsId === newResourceActivityDetailsId);
            if (foundNewResourceActivityDetailsId) {
                const index = activities.findIndex((activity) => activity.resourceActivityDetailsId === oldResourceActivityDetailsId);
                if (index >= 0) {
                    activities[index] = foundNewResourceActivityDetailsId;
                    setActivities([...activities]);
                }
            }
        }
    };

    const setActivityFallback = async () => {
        if (activityFallbackTemplateId) {
            const fallbackTemplate = await getNoDataFoundTemplate(activityFallbackTemplateId, context);
            setFallbackTemplate(fallbackTemplate);
        }
        if (activityFallbackWebContentId) {
            const fallbackWebContent = await getNoDataFoundContent(activityFallbackWebContentId);
            setFallbackWebContent(fallbackWebContent);
        }
    };

    const removeActivityFallback = () => {
        setFallbackTemplate(null);
        setFallbackWebContent(null);
    };

    const handleScroll = () => {
        const scrollTop: number = window.scrollY;
        const sidebarheight: number = scrollObserver.current?.offsetHeight || 0;
        const windowheight: number = window.innerHeight;
        if (scrollTop >= sidebarheight - windowheight) {
            loadMoreResults();
        }

        const container = scrollObserver.current;
        if (!container) {
            return;
        }
        if (enableWeekScrolling && updateDayViewDate && !isAutoScrolling) {
            const dayHeaders = container.querySelectorAll('h6[id^="day-"]');
            let currentVisibleDay = selectedDayIndex;

            const currentPosition = window.innerHeight / 2;
            let closestAbove: Element | null = null;
            let closestBelow: Element | null = dayHeaders[0];

            dayHeaders.forEach((dayHeader) => {
                const rect = dayHeader.getBoundingClientRect();
                const elementTop = rect.top;

                if (elementTop < currentPosition) {
                    if (!closestAbove || elementTop > closestAbove.getBoundingClientRect().top) {
                        closestAbove = dayHeader;
                    }
                } else if (elementTop > currentPosition) {
                    if (!closestBelow || elementTop < closestBelow.getBoundingClientRect().top) {
                        closestBelow = dayHeader;
                    }
                }
            });
            if (closestBelow && !closestAbove) {
                currentVisibleDay = closestBelow.id.replace("day-", "");
            } else if (closestAbove !== null) {
                currentVisibleDay = (closestAbove as Element).id.replace("day-", "");
            }

            if (currentVisibleDay) {
                updateDayViewDate(currentVisibleDay);
            }
        }
    };

    const loadMoreResults = () => setToIndex((prev) => prev + (+options.nextNumberOfTypes! || 5));

    if (fallbackTemplate || fallbackWebContent) {
        return <div className="no-result-found">{renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context })}</div>;
    }
    const loader: JSX.Element = options.resultsPanelGrid ? <Loader type="activity-grid-view" /> : <Loader type="typeSearchContainerList" views="type-search-container-list" />;
    if (isLoading) {
        return loader;
    }
    function getActivityContainerBody(accoReservationResults: Activity[], crpElements: JSX.Element): JSX.Element {
        return (
            <ErrorBoundary>
                <div className={classNames("search-results-wrap activity-search", `${!hideDisplayPatternClassName ? className : ""} ${hideWidget}`)} ref={scrollObserver}>
                    {options?.resultStyleSelector !== ResultStyleSelector.DISPLAY_STYLE_HIDE && <div className="result-count">{resultCount}</div>}
                    <React.Fragment>
                        <div className={`search-results-wrapper ${displayTypeContainer}`}>{crpElements}</div>
                        {getLoadMoreButton()}
                    </React.Fragment>
                </div>
            </ErrorBoundary>
        );
    }

    function getLoadMoreButton() {
        const {
            context: { currentLocale, site },
            options,
        } = props;
        return (
            <div>
                {options.showLoadMoreButton && activities && slicedActivities && toIndex < activities.length ? (
                    <div className="panel text-center">
                        <Button onClick={loadMoreResults} className="button button--l  button--secondary book-btn">
                            <FontAwesome name="spinner" className={classNames("searchfacet-progress", "no-progress")} />
                            {" " + getI18nLocaleString(namespaceList.widgetTypeSearch, "fetchNextItems", currentLocale, site)}
                            <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    }

    let dateTimeLabel = "";
    const dateTimeSortingLayout = slicedActivities?.map((activity, index) => {
        let showTime = false;
        const styles: React.CSSProperties = {
            background: options?.cancelledActivitiesBackground && options.cancelledActivitiesBackground.indexOf("rgba") > -1 ? options.cancelledActivitiesBackground : undefined,
        };
        const dateFormat = (options.activityDisplayPattern === DisplayPattern.DAY_SELECTION && options.showDateWithDayView && options.dayViewDateLabelFormat) || DATE_FORMAT.LONG_DATE;
        const formattedStartTime = moment(activity.day).format(dateFormat);
        if (dateTimeLabel !== formattedStartTime) {
            showTime = true;
            dateTimeLabel = formattedStartTime;
        }
        return (
            <div key={activity.resourceActivityDetailsId} className="sorted-date-wrap">
                {showTime && (
                    <h6 className="sorted-date" id={`day-${moment(activity.day).format(DATE_FORMAT.DISPLAY)!}`}>
                        <FontAwesome name="calendar mr-2" />
                        {formattedStartTime}
                    </h6>
                )}
                <div
                    className={`crp-elements ${options.resultsPanelGrid ? "activity-grid-view" : "activity-list-view"} ${displayTypeChild} ${
                        activity.status === ActivityStatus.CANCELLED ? "activity-cancelled" : ""
                    }`}
                    key={index}
                >
                    {activity.status === ActivityStatus.CANCELLED && (
                        <div className={`activity-cancelled--text ${backgroundColorPicker(cancelledActivitiesBackground)}`} style={styles}>
                            {cancelledActivityText && <span>{cancelledActivityText}</span>}
                        </div>
                    )}
                    {targetCrpWidgets && renderActivitySearchPanel({ childrenArray: targetCrpWidgets, elementsArray: slicedActivities, index, handleTimeSelection })}
                </div>
            </div>
        );
    });

    const crpElements = (
        <React.Fragment>
            {(targetCrpWidgets?.length && !options?.enableVirtualization) || displayTypeContainer === "row" ? (
                <React.Fragment>
                    {dynamicFilter.sortingOption && !hideDateLabel && [Sort[Sort.minToMaxDateAndTime], Sort[Sort.maxToMinDateAndTime]].includes(dynamicFilter.sortingOption)
                        ? dateTimeSortingLayout
                        : slicedActivities?.map((activity, index) => (
                              <div
                                  className={`crp-elements ${options.resultsPanelGrid ? "activity-grid-view" : "activity-list-view"} ${displayTypeChild} ${
                                      activity.isFullDayActivity ? "activity-full-day" : ""
                                  } ${activity.status === ActivityStatus.CANCELLED ? "activity-cancelled" : ""} ${activity.activityReservabilities?.[0]?.reservable === 0 ? "activity-sold-out" : ""}`}
                                  key={activity.resourceActivityDetailsId}
                              >
                                  {activity.status === ActivityStatus.CANCELLED && (
                                      <div className="activity-cancelled--text">
                                          <span>{cancelledActivityText}</span>
                                      </div>
                                  )}
                                  {targetCrpWidgets && renderActivitySearchPanel({ childrenArray: targetCrpWidgets, elementsArray: slicedActivities, index, handleTimeSelection })}
                              </div>
                          ))}
                </React.Fragment>
            ) : (
                renderVirtualizedList?.(activities, renderActivitySearchPanel, { childrenArray: targetCrpWidgets })
            )}
        </React.Fragment>
    );
    return getActivityContainerBody(activities, crpElements);
};

ActivitySearchContainerBase.injectedProps = { widgetType: "ActivitySearchContainer" };

export const ActivitySearchContainerWidget = withVirtualization(ActivitySearchContainerBase);
