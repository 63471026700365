import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { Activity, DaysChunks } from "../../page/activityPlanner/activityPlanner.types";
import { ActivityStatus, ApiCallOptions, MxtsApi, ResourceActivitiesDetailsResponse } from "@maxxton/cms-mxts-api";
import { CRPLayouts, renderNoResultsFoundContent } from "../containerWidget.util";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { WebContent, WithId } from "@maxxton/cms-api";
import { cloneDeep, isEqual } from "lodash";
import { filterActivitiesByCategory, updatePreFilterCategories } from "./activitySearchContainer.util";
import {
    formatDays,
    getActivitiesDetailsQueryParams,
    getGroupedActivitySectionThroughoutDay,
    getLinkedCustomSubjects,
    mainActivityFromMultipleSections,
    setDefaultStartDate,
    sortActivities,
    updateActivityWithSubjectName,
    updateStartDate,
} from "../../page/activityPlanner/activityPlanner.util";
import { getNoDataFoundContent, getNoDataFoundTemplate, shouldDisableLink } from "../../../components/utils";
import { useDispatch, useSelector } from "react-redux";

import { ActivitySearchContainerWidget } from "./activitySearchContainerWidget";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { Loader } from "../../../components/Loader";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { getMxtsEnv } from "../../mxts";

export interface ActivityCrpDayViewProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    className: string;
    crpLayouts: CRPLayouts;
}
// eslint-disable-next-line max-lines-per-function
export const ActivityCrpDayView = (props: ActivityCrpDayViewProps): JSX.Element => {
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter || {});
    const isFrontEndEditable: boolean = useSelector((state: State) => !!state.frontendPageEditState.isFrontEndEditable);

    const [selectedDay, setSelectedDay] = React.useState<string>(moment().format(DATE_FORMAT.DISPLAY));
    const [selectedDayIndex, setSelectedDayIndex] = React.useState<string>(selectedDay);
    const [daysInChunks, setDaysInChunks] = React.useState<DaysChunks[][]>([]);
    const [originalListOfActivities, setOriginalListOfActivities] = React.useState<Activity[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState<number>(-1);
    const [disableLeftButton, setDisableLeftButton] = React.useState<boolean>(false);
    const [disableRightButton, setDisableRightButton] = React.useState<boolean>(false);
    const [env, setEnv] = React.useState<ApiCallOptions>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [fallbackTemplate, setFallbackTemplate] = React.useState<JSX.Element[] | null>(null);
    const [fallbackWebContent, setFallbackWebContent] = React.useState<(WebContent & WithId) | null>(null);
    const dispatchAction = useDispatch();
    const { categoryFilters } = dynamicFilter;

    const { context, options, className, crpLayouts } = props;
    const { resortIdMultiSelector, activityFallbackTemplateId, activityFallbackWebContentId, showOnlyPublishedActivity, dayViewDateFormat, showCancelledActivities } = options;
    const dateFormat = dayViewDateFormat || DATE_FORMAT.DISPLAY;
    const shouldHideDateLabel = !(options.dayViewDateFormat && options.showDateWithDayView);
    let currentDate = dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.DISPLAY) : selectedDay;

    const loader: JSX.Element = options.resultsPanelGrid ? <Loader type="activity-grid-view" /> : <Loader type="typeSearchContainerList" views="type-search-container-list" />;
    React.useEffect(() => {
        setDefaultStartDate(options, dynamicFilter, dispatchAction);
    }, []);
    React.useEffect(() => {
        setSelectedDayIndex(selectedDay);
    }, [selectedDay]);
    React.useEffect(() => {
        const setdays = async () => {
            setIsLoading(true);
            if (!currentDate) {
                currentDate = moment().format(DATE_FORMAT.DISPLAY);
            }
            setSelectedDay(currentDate);
            const currentDateMoment = moment(currentDate, DATE_FORMAT.DISPLAY);
            const envResponse = await getMxtsEnv(context, context?.currentLocale.code);
            setEnv(envResponse);
            const detailParams = await getActivitiesDetailsQueryParams({
                resortIdMultiSelector,
                mxtsApi: context.mxtsApi,
                env: envResponse,
                dynamicFilter,
                startDate: currentDateMoment.clone().startOf("week").format(DATE_FORMAT.MXTS),
                endDate: currentDateMoment.clone().endOf("week").format(DATE_FORMAT.MXTS),
                showOnlyPublishedActivity,
                showCancelledActivities,
            });
            let activitiesDetails = (await MxtsApi.getResourceActivitiesDetails(envResponse, detailParams))?.content;
            if (showOnlyPublishedActivity && showCancelledActivities) {
                activitiesDetails = activitiesDetails.filter((activity) => activity.status === ActivityStatus.CANCELLED || (activity.status === ActivityStatus.PUBLISHED && activity.published));
            }
            activitiesDetails = await updateActivityWithSubjectName(activitiesDetails, context.mxtsApi, envResponse);
            // Just before grouping the activities, store the original activities in state variable so that we can use to find selected ResourceActivityDetailsId in handleTimeSelection function
            activitiesDetails = await getLinkedCustomSubjects(envResponse, activitiesDetails);
            setOriginalListOfActivities(cloneDeep(activitiesDetails));
            const response = transformActivities(activitiesDetails);
            setDaysInChunks(response);
            setIsLoading(false);
            // Make left and right arrow enabled
            setDisableRightButton(false);
            setDisableLeftButton(currentDateMoment.clone().startOf("week").isSameOrBefore(moment()));
        };
        setdays();
    }, [dynamicFilter.startdate, dynamicFilter.enddate, dynamicFilter.resortids]);

    const transformActivities = (activitiesDetails: ResourceActivitiesDetailsResponse[], originalListOfActivities?: Activity[]) => {
        const chunkedDays = formatDays(dynamicFilter);
        const clonedDaysInChunks = [...chunkedDays];
        activitiesDetails = getGroupedActivitySectionThroughoutDay(activitiesDetails);
        activitiesDetails = mainActivityFromMultipleSections(activitiesDetails, options);

        updateCategoriesToDynamicFilter(activitiesDetails, originalListOfActivities);

        clonedDaysInChunks.forEach((chunkedDays, index) => {
            const isSelectedWeek = chunkedDays.some((dayInXChunk) => dayInXChunk.day === currentDate);
            if (isSelectedWeek) {
                chunkedDays.forEach((chunkedDay, dayIndex) => {
                    const isSelectedDay = chunkedDay.day === currentDate;
                    if (moment(chunkedDay.day, DATE_FORMAT.DISPLAY).isSameOrAfter(moment(currentDate, DATE_FORMAT.DISPLAY).startOf("day"))) {
                        const activitiesForDay = activitiesDetails.filter((activity) => moment(activity.day).format(DATE_FORMAT.DISPLAY) === chunkedDay.day);
                        chunkedDay.activities = activitiesForDay;
                    }
                    if (isSelectedDay) {
                        setCurrentIndex(index);
                        if (!activitiesDetails.length) {
                            setActivityFallback();
                        } else {
                            removeActivityFallback();
                        }
                    }
                });
            }
        });
        return clonedDaysInChunks;
    };

    React.useEffect(() => {
        const { allIds, selectedIds } = categoryFilters ?? {};

        let activities = cloneDeep(originalListOfActivities);

        if (allIds?.length && activities?.length) {
            let response: DaysChunks[][];
            if (selectedIds?.length) {
                activities = cloneDeep(filterActivitiesByCategory(originalListOfActivities, dynamicFilter?.categoryFilters));
                response = transformActivities(activities, originalListOfActivities);
            } else {
                response = transformActivities(activities, activities);
            }
            setDaysInChunks(response);
        }
    }, [categoryFilters?.selectedIds, originalListOfActivities]);

    React.useEffect(() => {
        const clonedDaysInChunks = [...daysInChunks];
        if ([Sort[Sort.minToMaxDateAndTime], Sort[Sort.maxToMinDateAndTime]].includes(dynamicFilter.sortingOption || "") && clonedDaysInChunks.length) {
            clonedDaysInChunks.forEach((chunkedDay) => {
                const foundDay = chunkedDay.find((dayInXChunk) => dayInXChunk.day === selectedDay);
                if (foundDay) {
                    foundDay.activities = sortActivities(foundDay.activities, dynamicFilter.sortingOption || "");
                }
            });
        }
        setDaysInChunks(cloneDeep(clonedDaysInChunks));
    }, [dynamicFilter.sortingOption]);

    const updateDayViewDate = (selectedDate: string) => {
        setSelectedDayIndex(selectedDate);
    };

    const setActivityFallback = async () => {
        if (activityFallbackTemplateId) {
            const fallbackTemplate = await getNoDataFoundTemplate(activityFallbackTemplateId, context);
            setFallbackTemplate(fallbackTemplate);
        }
        if (activityFallbackWebContentId) {
            const fallbackWebContent = await getNoDataFoundContent(activityFallbackWebContentId);
            setFallbackWebContent(fallbackWebContent);
        }
    };

    const updateCategoriesToDynamicFilter = (activitiesDetails: ResourceActivitiesDetailsResponse[], originalListOfActivities?: Activity[]) => {
        const ids: number[] = [];
        (originalListOfActivities || activitiesDetails)?.forEach((activity) => {
            const categortId = activity?.resourceActivity?.resortActivity?.activityCategoryId;
            if (!ids?.includes(categortId)) {
                ids.push(categortId);
            }
        });
        if (!categoryFilters?.allIds?.length || !isEqual(Array.from(ids), categoryFilters?.allIds)) {
            const selectedCategories = categoryFilters?.selectedIds?.filter((category) => ids?.includes(category)) || [];
            updatePreFilterCategories(ids, selectedCategories, dispatchAction);
        }
    };

    const removeActivityFallback = () => {
        setFallbackTemplate(null);
        setFallbackWebContent(null);
    };

    const handleDateClick = async (clickedDay: string) => {
        const isClickedDayRendered = document.querySelector(`#day-${clickedDay}`);
        if (options.enableWeekScrolling && selectedDayIndex !== clickedDay && isClickedDayRendered) {
            setSelectedDayIndex(clickedDay);
        } else if (selectedDay !== clickedDay) {
            setSelectedDay(clickedDay);
            const dateSelected = moment(clickedDay, DATE_FORMAT.DISPLAY).format(DATE_FORMAT.DEFAULT);
            updateStartDate(dateSelected, dispatchAction);
        }
    };

    // Go to previous week
    function handleAngleLeft() {
        const updatedIndex = currentIndex - 1;
        const daysAfterLeftClick = daysInChunks[updatedIndex];
        if (!daysAfterLeftClick) {
            setDisableLeftButton(true);
        }
        const firstAvailableDay = daysAfterLeftClick.find((dayAfterLeftClick) => moment(dayAfterLeftClick.day, DATE_FORMAT.DISPLAY).isSameOrAfter(moment().startOf("day")));
        if (firstAvailableDay) {
            setSelectedDay(firstAvailableDay.day);
            const dateSelected = moment(firstAvailableDay.day, DATE_FORMAT.DISPLAY).format(DATE_FORMAT.DEFAULT);
            updateStartDate(dateSelected, dispatchAction);
        }
        setDisableRightButton(false);
    }

    // Go to next week
    function handleAngleRight() {
        const updatedIndex = currentIndex + 1;
        const daysAfterRightClick = daysInChunks[updatedIndex];
        if (!daysAfterRightClick) {
            setDisableRightButton(true);
        }
        if (daysAfterRightClick[0].day) {
            setSelectedDay(daysAfterRightClick[0].day);
            const dateSelected = moment(daysAfterRightClick[0].day, DATE_FORMAT.DISPLAY).format(DATE_FORMAT.DEFAULT);
            updateStartDate(dateSelected, dispatchAction);
        }
        setDisableLeftButton(false);
    }

    const handleTimeSelection = (oldResourceActivityDetailsId: number, newResourceActivityDetailsId: number) => {
        const foundNewResourceActivityDetailsId = originalListOfActivities.find((activities) => activities.resourceActivityDetailsId === newResourceActivityDetailsId);
        if (foundNewResourceActivityDetailsId) {
            daysInChunks[currentIndex].forEach((dayList) => {
                if (dayList.day === selectedDay) {
                    const index = dayList.activities.findIndex((activity) => activity.resourceActivityDetailsId === oldResourceActivityDetailsId);
                    if (index >= 0) {
                        dayList.activities[index].resourceActivityDetailsId = foundNewResourceActivityDetailsId.resourceActivityDetailsId;
                    }
                }
            });
            setDaysInChunks(cloneDeep(daysInChunks));
        }
    };

    return (
        <div className={`agenda-wrap activity-day-view ${className}`}>
            <div className={`agenda-tabs-wrap ${options.enableWeekScrolling ? "sticky" : ""}`}>
                <a className={`agenda-tabs-left-arrow tabs-arrows ${disableLeftButton || shouldDisableLink(isFrontEndEditable) ? "disabled" : "enabled"}`} onClick={handleAngleLeft}>
                    <FontAwesome name="angle-left" />
                </a>
                <div className="scroller-wrapper">
                    <div className="scroller">
                        <Nav className="agenda-tabs" tabs>
                            {daysInChunks[currentIndex]?.map((key) => (
                                <NavItem className="agenda-tab-date" key={key.day}>
                                    <NavLink
                                        className={classNames({ active: selectedDayIndex === key.day }, { disabled: moment(key.day, DATE_FORMAT.DISPLAY).isBefore(moment().startOf("day")) })}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDateClick(key.day);
                                        }}
                                    >
                                        {moment(key.day, DATE_FORMAT.DISPLAY).format(dateFormat)}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                </div>
                <a className={`agenda-tabs-right-arrow tabs-arrows ${disableRightButton || shouldDisableLink(isFrontEndEditable) ? "disabled" : "enabled"}`} onClick={handleAngleRight}>
                    <FontAwesome name="angle-right" />
                </a>
            </div>
            <TabContent activeTab={selectedDay}>
                {fallbackTemplate || fallbackWebContent ? (
                    <div>{renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context })}</div>
                ) : isLoading ? (
                    loader
                ) : (
                    daysInChunks[currentIndex]?.map((key) => {
                        if (key.day === selectedDay && key.activities.length) {
                            return (
                                <TabPane key={key.day} tabId={key.day}>
                                    <ActivitySearchContainerWidget
                                        dayActivities={key.activities}
                                        className={className}
                                        options={options}
                                        context={context}
                                        crpLayouts={crpLayouts}
                                        hideDateLabel={shouldHideDateLabel}
                                        hideDisplayPatternClassName={true}
                                        originalListOfActivitiesFromDayView={originalListOfActivities}
                                        handleTimeSelectionForDayView={handleTimeSelection}
                                        week={daysInChunks[currentIndex]}
                                        enableWeekScrolling={options.enableWeekScrolling}
                                        updateDayViewDate={updateDayViewDate}
                                        selectedDayIndex={selectedDayIndex}
                                    />
                                </TabPane>
                            );
                        }
                    })
                )}
            </TabContent>
        </div>
    );
};
