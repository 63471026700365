import * as React from "react";

import { AlternativeColumnOptions, colSelectOptions } from "../../page/columns";
import { DefaultDate, DisplayPattern } from "../../page/activityPlanner/activityPlanner.enum";
import { FormSpec, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { I18nRESORT, RESORT, resortMultiSelector } from "../../../components/utils";
import { PageWidgetSpec, Widget } from "../../widget";

import { ActivityCalender } from "../../page/activityPlanner/activityCalendar";
import { ActivityCrpDayView } from "./activityCrpDayView";
import { ActivitySearchContainerWidget } from "./activitySearchContainerWidget";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { CRPLayouts } from "../containerWidget.util";
import { ElementsUtil } from "../../../utils/elements.util";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { ResultStyleSelector } from "../../../utils/constants";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getActivityDateFormatOptions } from "../../../inputSpecs/dateFormatsSelect";
import { getI18nLocaleObject } from "../../../i18n";
import { getResultPanelElements } from "../../resultsPanel/resultsPanelUtil";
import namespaceList from "../../../i18n/namespaceList";
import { resultsPanelSpec } from "../../../form-specs/models/resultsPanel";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions extends AlternativeColumnOptions {
    contentType?: string;
    resortIdMultiSelector?: NumberMultiSelectOption[];
    styleIds: string[];
    activityFallbackWebContentId?: string;
    activityFallbackTemplateId?: string;
    resultsPanelList: string;
    multipleLayouts: boolean;
    resultsPanelGrid: string;
    resultsPanelTablet: string;
    resultsPanelMobile: string;
    showLoadMoreButton: boolean;
    enableVirtualization?: boolean;
    defaultNumberOfTypes?: number;
    nextNumberOfTypes?: number;
    resultStyleSelector?: string;
    activityDisplayPattern?: DisplayPattern;
    defaultNumberOfMonths?: number;
    showOnlyPublishedActivity: boolean;
    dayViewDateFormat?: string;
    defaultStartDate?: string;
    selectedDefaultStartDate?: Date;
    showDateWithDayView?: boolean;
    dayViewDateLabelFormat?: string;
    showMainActivityName?: boolean;
    showCancelledActivities?: boolean;
    cancelledActivitiesBackground?: string;
    localizedOptions?: LocalizedActivitySearchOptions[];
    enableWeekScrolling?: boolean;
}

interface LocalizedActivitySearchOptions extends LocalizedContentBase {
    cancelledActivitiesText?: string;
}

const TARGETS = ["activity-search-container"];

/* jscpd:ignore-start */
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "activitysearch-container-widget",
    name: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "activitySearchContainerWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "activitySearchContainerWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                /* jscpd:ignore-start */
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "general"),
                    properties: [
                        [
                            {
                                variable: "contentType",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "contentType"),
                                type: "select",
                                optionList: [
                                    {
                                        value: RESORT,
                                        label: I18nRESORT,
                                    },
                                ],
                            },
                            resortMultiSelector("resortIdMultiSelector"),
                        ],
                        [
                            {
                                variable: "resultsPanelList",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelList"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                            },
                            {
                                variable: "resultsPanelGrid",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelGrid"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                            },
                            {
                                variable: "defaultStartDate",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "defaultStartDate"),
                                type: "select",
                                optionList: [
                                    {
                                        value: DefaultDate.TODAY,
                                        label: getI18nLocaleObject(namespaceList.widgetDateFilter, "today"),
                                    },
                                    {
                                        value: DefaultDate.TOMORROW,
                                        label: getI18nLocaleObject(namespaceList.widgetDateFilter, "tomorrow"),
                                    },
                                    {
                                        value: DefaultDate.ANOTHER_DAY,
                                        label: getI18nLocaleObject(namespaceList.widgetDateFilter, "anotherDay"),
                                    },
                                ],
                            },
                            {
                                variable: "selectedDefaultStartDate",
                                label: getI18nLocaleObject(namespaceList.widgetDateFilter, "selectDefaultStartDate"),
                                type: "date",
                                visible: (option: WidgetOptions) => option.defaultStartDate === DefaultDate.ANOTHER_DAY,
                            },
                            {
                                variable: "multipleLayouts",
                                label: getI18nLocaleObject(namespaceList.displayGridListMapWidget, "multipleLayouts"),
                                type: "checkbox",
                            },
                            {
                                variable: "resultsPanelMobile",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelMobile"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => options.multipleLayouts,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForCRPMobileLayout"),
                            },
                            {
                                variable: "resultsPanelTablet",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelTablet"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => options.multipleLayouts,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForCRPTabletLayout"),
                            },
                            {
                                variable: "showMainActivityName",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "showMainActivityName"),
                                type: "checkbox",
                            },
                            {
                                variable: "showOnlyPublishedActivity",
                                label: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "showOnlyPublishedActivity"),
                                default: true,
                                type: "checkbox",
                            },
                            {
                                variable: "showCancelledActivities",
                                label: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "showCancelledActivities"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showOnlyPublishedActivity,
                            },
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        variable: "cancelledActivitiesText",
                                        label: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "cancelledActivityText"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => (options.showOnlyPublishedActivity ? !!options.showCancelledActivities : true),
                            }),
                            {
                                variable: "cancelledActivitiesBackground",
                                label: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "cancelledActivitiesBackground"),
                                type: "dual-color",
                                default: "font-default",
                                visible: (options: WidgetOptions) => (options.showOnlyPublishedActivity ? !!options.showCancelledActivities : true),
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesGridDisplay"),
                    properties: [
                        [
                            {
                                variable: "columnsResp",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsResponsive"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columns",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsMobile"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsTab",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsTablet"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsDesk",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsDesktop"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                        ],
                    ],
                    visible: (options: WidgetOptions) => (options.resultsPanelGrid ? true : false),
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "display"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionUnitSearchDisplay"),
                            },
                            {
                                variable: "activityDisplayPattern",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityDisplayPattern"),
                                type: "select",
                                optionList: [
                                    {
                                        value: DisplayPattern.DAY_SELECTION,
                                        label: getI18nLocaleObject(namespaceList.admin, "dayView"),
                                    },
                                    {
                                        value: DisplayPattern.CALENDAR,
                                        label: getI18nLocaleObject(namespaceList.admin, "calendar"),
                                    },
                                ],
                            },
                            {
                                variable: "dayViewDateFormat",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "dateType"),
                                optionList: getActivityDateFormatOptions(),
                                type: "select",
                                visible: (options: WidgetOptions) => options.activityDisplayPattern === DisplayPattern.DAY_SELECTION,
                            },
                            {
                                variable: "showDateWithDayView",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "showDateWithDayView"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.activityDisplayPattern === DisplayPattern.DAY_SELECTION,
                            },
                            {
                                variable: "dayViewDateLabelFormat",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "dayViewDateLabelFormat"),
                                type: "select",
                                optionList: getActivityDateFormatOptions(),
                                visible: (options: WidgetOptions) => options.activityDisplayPattern === DisplayPattern.DAY_SELECTION && !!options.showDateWithDayView,
                            },
                            {
                                variable: "enableWeekScrolling",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "enableScrollSpy"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.activityDisplayPattern === DisplayPattern.DAY_SELECTION,
                            },
                            {
                                variable: "resultStyleSelector",
                                label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultStyleSelector"),
                                type: "radioImage",
                                default: ResultStyleSelector.DEFAULT,
                                radioOptions: [
                                    {
                                        value: ResultStyleSelector.DISPLAY_STYLE_HIDE,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountHideLabel"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountHide")),
                                    },
                                    {
                                        value: ResultStyleSelector.DEFAULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabelDefault"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountDefault")),
                                    },
                                    {
                                        value: ResultStyleSelector.SHOWING_X_RESULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabel1"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountOption1")),
                                    },
                                    {
                                        value: ResultStyleSelector.X_RESULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabel2"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountOption2")),
                                    },
                                ],
                            },
                            {
                                variable: "defaultNumberOfTypes",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "defaultNumberOfActivities"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForDefaultNumberOfResults"),
                            },
                            {
                                variable: "nextNumberOfTypes",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "nextNumberOfActivities"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForDefaultNumberOfResults"),
                            },
                            {
                                variable: "defaultNumberOfMonths",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "defaultNumberOfMonths"),
                                type: "number",
                                default: 0,
                            },
                            {
                                variable: "showLoadMoreButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showLoadMoreButton"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "enableVirtualization",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "enableVirtualization"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "activityFallbackWebContentId",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityFallbackWebContentId"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                            },
                            {
                                variable: "activityFallbackTemplateId",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityFallbackTemplateId"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                            },
                        ],
                    ],
                },
                /* jscpd:ignore-end */
            ],
        },
    ],
};
/* jscpd:ignore-end */
export const activitySearchContainerWidget: PageWidgetSpec<WidgetOptions> = {
    id: "activitySearchContainerWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "activitySearchContainerWidget"),
    description: getI18nLocaleObject(namespaceList.pluginActivitySearchContainer, "activitySearchContainerWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        multipleLayouts: false,
        resultsPanelList: "",
        resultsPanelGrid: "",
        resultsPanelTablet: "",
        resultsPanelMobile: "",
        styleIds: [],
        showLoadMoreButton: false,
        defaultNumberOfTypes: undefined,
        nextNumberOfTypes: undefined,
        resultStyleSelector: ResultStyleSelector.DEFAULT,
        showOnlyPublishedActivity: true,
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const { resultsPanelList, resultsPanelGrid, resultsPanelTablet, resultsPanelMobile, styleIds, activityDisplayPattern } = widget.options;

        const crpLayouts: CRPLayouts = {
            grid: await getResultPanelElements(resultsPanelGrid, context),
            list: await getResultPanelElements(resultsPanelList, context),
            mobile: await getResultPanelElements(resultsPanelMobile, context),
            tablet: await getResultPanelElements(resultsPanelTablet, context),
        };
        if (!crpLayouts.grid && crpLayouts.list && crpLayouts.mobile && crpLayouts.tablet) {
            throw new Error("No TypeLayout selected");
        }
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        if (activityDisplayPattern === DisplayPattern.DAY_SELECTION) {
            return <ActivityCrpDayView className={styleClasses} options={widget.options} context={context} crpLayouts={crpLayouts} />;
        } else if (activityDisplayPattern === DisplayPattern.CALENDAR) {
            return <ActivityCalender options={widget.options} context={context} />;
        }
        return <ActivitySearchContainerWidget className={styleClasses} options={widget.options} context={context} crpLayouts={crpLayouts} />;
    },
};
