import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { ApiCallOptions, MxtsApi, ResourceActivitiesDetailsResponse } from "@maxxton/cms-mxts-api";
import {
    datesAreOnSameDay,
    getActivitiesDetailsQueryParams,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getMonthYear,
    getSortedDays,
    matchMonthAndYear,
    structureCalendarActivity,
} from "./activityPlanner.util";

import { WidgetOptions as ActivityCrpWidgetOptions } from "../../dynamic/activitySearchContainer/index";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { cloneDeep } from "lodash";
import { getMxtsEnv } from "../../mxts";
import { useSelector } from "react-redux";

export interface ActivityCalenderProps {
    options: WidgetOptions | ActivityCrpWidgetOptions;
    context: CMSProvidedProperties;
    url?: string;
}

export interface WholeMonthData {
    day: Date;
    resourceActivityDetail: ResourceActivitiesDetailsResponse[];
}

export interface StructureCalendar {
    firstDayOfMonth: Date;
    wholeMonthData: WholeMonthData[];
}

const DAYS = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

export const ActivityCalender = (props: ActivityCalenderProps) => {
    const {
        context,
        options: { resortIdMultiSelector },
    } = props;
    const dynamicFilter: DynamicFilter = useSelector((state: State) => state.dynamicFilter || {});
    const [currentDate, setCurrentDate] = React.useState<Date>(getFirstDayOfMonth());
    // const [showPortal, setShowPortal] = React.useState<boolean>(false);
    // const [portalData, setPortalData] = React.useState({});
    const [env, setEnv] = React.useState<ApiCallOptions>();
    const [structureCalendar, setStructureCalendar] = React.useState<StructureCalendar[]>([]);
    // const handlePotalClose = () => setShowPortal(false);

    React.useEffect(() => {
        const fetchAndSetActivity = async () => {
            const env = await getMxtsEnv(context, context?.currentLocale.code);
            setEnv(env);
            const detailParams = await getActivitiesDetailsQueryParams({
                resortIdMultiSelector,
                mxtsApi: context.mxtsApi,
                env,
                dynamicFilter,
                startDate: moment(getFirstDayOfMonth(currentDate)).format(DATE_FORMAT.MXTS),
                endDate: moment(getLastDayOfMonth(currentDate)).format(DATE_FORMAT.MXTS),
                showOnlyPublishedActivity: (props.options as ActivityCrpWidgetOptions).showOnlyPublishedActivity,
            });
            const activitiesDetails = await MxtsApi.getResourceActivitiesDetails(env, detailParams).then((result) => result.content);
            const structuredCalendarActivity = structureCalendarActivity(currentDate, activitiesDetails, structureCalendar);
            setStructureCalendar(structuredCalendarActivity);
        };
        fetchAndSetActivity();
    }, []);

    const nextMonth = async (date: Date) => {
        const cloneDate = cloneDeep(date);
        const month = cloneDate.getMonth();
        if (month < 11) {
            cloneDate.setMonth(month + 1);
        } else {
            cloneDate.setMonth(0);
            cloneDate.setFullYear(cloneDate.getFullYear() + 1);
        }
        const currentDate = new Date(cloneDate);
        setCurrentDate(currentDate);
        const detailParams =
            env &&
            (await getActivitiesDetailsQueryParams({
                resortIdMultiSelector,
                mxtsApi: context.mxtsApi,
                env,
                dynamicFilter,
                startDate: moment(getFirstDayOfMonth(currentDate)).format(DATE_FORMAT.MXTS),
                endDate: moment(getLastDayOfMonth(currentDate)).format(DATE_FORMAT.MXTS),
                showOnlyPublishedActivity: (props.options as ActivityCrpWidgetOptions).showOnlyPublishedActivity,
            }));
        const activitiesDetails = env && detailParams && (await MxtsApi.getResourceActivitiesDetails(env, detailParams).then((result) => result.content));
        const structuredCalendarActivity = structureCalendarActivity(currentDate, activitiesDetails || [], structureCalendar);
        setStructureCalendar(structuredCalendarActivity);
    };

    const prevMonth = async (date: Date) => {
        const cloneDate = cloneDeep(date);
        const month = cloneDate.getMonth();
        if (month > 0) {
            cloneDate.setMonth(month - 1);
        } else {
            cloneDate.setMonth(11);
            cloneDate.setFullYear(cloneDate.getFullYear() - 1);
        }
        const currentDate = new Date(cloneDate);
        setCurrentDate(currentDate);
        const detailParams =
            env &&
            (await getActivitiesDetailsQueryParams({
                resortIdMultiSelector,
                mxtsApi: context.mxtsApi,
                env,
                dynamicFilter,
                startDate: moment(getFirstDayOfMonth(currentDate)).format(DATE_FORMAT.MXTS),
                endDate: moment(getLastDayOfMonth(currentDate)).format(DATE_FORMAT.MXTS),
                showOnlyPublishedActivity: (props.options as ActivityCrpWidgetOptions).showOnlyPublishedActivity,
            }));
        const activitiesDetails = env && detailParams && (await MxtsApi.getResourceActivitiesDetails(env, detailParams).then((result) => result.content));
        const structuredCalendarActivity = structureCalendarActivity(currentDate, activitiesDetails || [], structureCalendar);
        setStructureCalendar(structuredCalendarActivity);
    };

    return (
        <div className="calendar-wrapper">
            <RenderCalendarHeader currentDate={currentDate} nextMonth={nextMonth} prevMonth={prevMonth} />
            <RenderDays />
            <RenderCalendarBody currentDate={currentDate} structureCalendar={structureCalendar} />
            {/* {showPortal && <Portal {...portalData} handlePotalClose={handlePotalClose} />} */}
        </div>
    );
};

const RenderCalendarHeader = ({ currentDate, nextMonth, prevMonth }: { currentDate: Date; nextMonth: (date: Date) => void; prevMonth: (date: Date) => void }) => (
    <div className="date-controls">
        <a className="agenda-tabs-left-arrow tabs-arrows disabled" onClick={() => prevMonth(currentDate)}>
            <FontAwesome name="angle-left" />
        </a>
        {getMonthYear(currentDate)}
        <a className="agenda-tabs-right-arrow tabs-arrows" onClick={() => nextMonth(currentDate)}>
            <FontAwesome name="angle-right" />
        </a>
    </div>
);

const RenderDays = () => (
    <div className="sevenColGrid">
        {DAYS.map((day, index) => (
            <span key={index} className="headDays">
                {day}
            </span>
        ))}
    </div>
);

const RenderCalendarBody = ({ currentDate, structureCalendar }: { currentDate: Date; structureCalendar: StructureCalendar[] }) => (
    <div className={classNames("sevenColGrid", { "sevenColGrid--fullheight": true })}>
        {getSortedDays(currentDate).map((day, index) => (
            <div key={index} id={`${currentDate.getFullYear()}/${currentDate.getMonth()}/${day}`}>
                <span>{day}</span>
                {structureCalendar.map(
                    (event, index) =>
                        matchMonthAndYear(event.firstDayOfMonth, currentDate) && (
                            <EventWrapper key={index}>
                                {event.wholeMonthData.map(
                                    (monthData) =>
                                        datesAreOnSameDay(monthData.day, new Date(currentDate.getFullYear(), currentDate.getMonth(), day)) &&
                                        monthData.resourceActivityDetail.map((dataOfmonth, index) => (
                                            <div className="styledEvent" key={index}>
                                                <span>{`${moment(dataOfmonth.startTime).format(DATE_FORMAT.TIME)} - ${moment(dataOfmonth.endTime).format(DATE_FORMAT.TIME)}`}</span>
                                                <span>{dataOfmonth.resourceActivity.resortActivity.title}</span>
                                            </div>
                                        ))
                                )}
                            </EventWrapper>
                        )
                )}
            </div>
        ))}
    </div>
);

const EventWrapper = ({ children }: { children: any }) => {
    if (children.filter((child: any) => child).length) {
        return (
            <>
                {children}
                {/* {children.filter((child) => child)[0].length > 1 && (
                    <p
                        className="seeMore"
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log("clicked p");
                        }}
                    >
                        see more...
                    </p>
                )} */}
            </>
        );
    }
    return <></>;
};

// const Portal = ({ title, date, handlePotalClose }) => (
//     <div className="portalWrapper">
//         <h2>{title}</h2>
//         <p>{date.toDateString()}</p>
//         <ion-icon onClick={handlePotalClose} name="close-outline"></ion-icon>
//     </div>
// );
